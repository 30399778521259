(function($){
	$(document).ready(function(){

		  if(document.getElementById("countdowntext")){
		// Set the time at which the countdown expires.
		// var untilDate new Date(Year, Month - 1, Day)
		//-----------------------------------------------
		// var untilDate = new Date(2017, 10 - 1, 02);
		var now = new Date();
		// now = new Date(Date.UTC(2017, 09, 02, 12, 0, 0));
		var addTwi = 10+2
		var untilDate = new Date(Date.UTC(2017, 09, 02, 10, 0, 0));
		console.log(untilDate + "ok");
		console.log("saa");
		untilDate = untilDate + new Date(Date.UTC(2017, 09, 02, 10, 0, 0));
		console.log(untilDate);

		$('.preicoactive').show();
		var countText = document.createTextNode("ICO ENDS IN");
		document.querySelector("#countdowntext").appendChild(countText);
		untilDate = new Date(Date.UTC(2017, 11, 18, 0, 0, 0));
		lastDate = new Date(Date.UTC(2017, 11, 18, 0, 0, 0));
		console.log("hebbsyy");

		$(".countdown").countdown({
			until: untilDate,
			format: 'dHMS',
			padZeroes: true
		});
		}
	}); // End document ready

})(this.jQuery);

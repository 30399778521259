/* Theme Name:RefToken- Clean & Powerful Bootstrap Theme
 * Author:HtmlCoder
 * Author URI:http://www.htmlcoder.me
 * Author e-mail:htmlcoder.me@gmail.com
 * Version: 1.5
 * Created:October 2014
 * License URI:http://support.wrapbootstrap.com/
 * File Description: Place here your custom scripts
 */

 (function($){


 	$(document).ready(function(){
      $('.togglestop').click(function(){
          console.log("hsssdej");
      });
      $('.togglestop').click(function(event){
          event.preventDefault();
          console.log("test");
      });

      // particlesJS.load('particles-js', 'js/particlesjs-config.json', function() {
      //   console.log('callback - particles.js config loaded');
      // });

    // jQuery Validation
    $("#signup").validate({
      // if valid, post data via AJAX
      submitHandler: function(form) {
        $.post("https://reftoken.io/php/subscribe.php", {  email: $("#email").val() }, function(data) {
          $('#response').html(data);
        });
      },
      // all fields are required
      rules: {
        email: {
          required: true,
          email: true
        }
      }
    });

    // if(document.getElementById("download_wp_btn")){
    //   var downloadBtn = document.getElementById("download_wp_btn");
    //   downloadBtn.addEventListener("click", function(event){
    //       dataLayer.push({
    //   		"event" : "wpdownload",
    //   		"wpResult" : "downloaded"
    //   	});
    //
    //   fbq('track', 'wpdownload');
    //   })
    // }
    $('.prototypelink').click(function(){
      dataLayer.push({
        "event" : "wpdownload",
        "wpResult" : "downloaded"
      });
      fbq('track', 'wpdownload');
    });
    $('.prototypelink').click(function(){
      dataLayer.push({
        "event" : "protoclicked",
        "protoResult" : "clicked"
      });
      fbq('track', 'prototypelink');
    });
    $('.joinico').click(function(){
      dataLayer.push({
        "event" : "icobtn",
        "icoResult" : "clicked"
      });
      fbq('track', 'icosignup');
    });

  
  }); // End document ready

})(this.jQuery);
